import React, { Component } from 'react';
import { Accordion, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Styles from './SideBar.module.scss';
import ListLabel from './ListLabel.js';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import {
  Chart1,
  Flashy,
  People,
  PresentionChart,
  SearchNormal1,
  Setting2,
  Ticket,
  User,
  UserCirlceAdd,
  UserOctagon,
  ClipboardText,
  Book1,
  Call,
  CpuSetting,
  Verify,
  Autobrightness,
} from 'iconsax-react';
import DoubleArrowIcon from './DoubleArrowIcon.js';
import Menu from './Menu';
import ChatBotMessageIcon from '../../icon/ChatBotMessageIcon';
import MonitoringIcon from '../../icon/MonitoringIcon';
import ShopTalk from '../../icon/ShopTalk';
import { MENU } from '../../../const/AppConst';
import appStore from '../../../store/AppStore';

@observer
class SideBar extends Component {
  @observable
  collapsed = false;

  constructor(props, context) {
    super(props, context);
    makeObservable(this);
  }

  @action.bound
  handleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  render() {
    const { isAuthorization } = appStore;
    return (
      <div
        className={`${Styles.SideBar} ${this.collapsed ? Styles.Collapse : ''}`}
      >
        <div className={`${Styles.LogoWrap}`}>
          <div
            className={
              'w-100 align-items-center pd-0 d-flex justify-content-between flex-row'
            }
            onClick={this.handleCollapsed}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image
                src={`/asset/img/qt_logo.png`}
                style={{ width: '1.6rem', marginRight: '0.5rem' }}
              />
              {!this.collapsed && (
                <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>
                  SOONi
                </span>
              )}
            </div>
            <div className={Styles.CollapseButtonWrap}>
              <DoubleArrowIcon collapsed={this.collapsed} />
            </div>
          </div>
        </div>

        <div className={`${Styles.SideMenu}`}>
          <Accordion
            className={`${Styles.Accordion} ${this.collapsed ? Styles.AccordionColl : ''}`}
            alwaysOpen
            defaultActiveKey={['0', '1', '2', '3', '4', '5']}
            activeKey={
              this.collapsed ? ['0', '1', '2', '3', '4', '5'] : undefined
            }
          >
            {isAuthorization([MENU.COMPANY, MENU.ADMIN]) && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <ListLabel label="슈퍼관리자" collapsed={this.collapsed} />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.COMPANY}
                    collapsed={this.collapsed}
                    icon={<SearchNormal1 />}
                    label={'회사 관리'}
                    to={`/main/company`}
                  />
                  <Menu
                    auth={MENU.ADMIN}
                    collapsed={this.collapsed}
                    icon={<UserOctagon />}
                    label={'관리자'}
                    to={`/main/admin`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {isAuthorization([MENU.CHAT, MENU.TICKET]) && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <ListLabel label="티켓" collapsed={this.collapsed} />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.CHAT}
                    collapsed={this.collapsed}
                    icon={<Ticket />}
                    label={'나의티켓'}
                    to={`/main/chat`}
                  />
                  <Menu
                    auth={MENU.TICKET}
                    collapsed={this.collapsed}
                    icon={<SearchNormal1 />}
                    label="모든티켓 조회"
                    to={`/main/ticket`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {isAuthorization([MENU.DASH, MENU.MONITOR, MENU.REPORT]) && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <ListLabel label="대시보드" collapsed={this.collapsed} />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.DASH}
                    collapsed={this.collapsed}
                    icon={<Chart1 />}
                    label="대시보드"
                    to={`/main/dashboard`}
                  />
                  <Menu
                    auth={MENU.MONITOR}
                    collapsed={this.collapsed}
                    icon={<MonitoringIcon className={'MenuTitle'} />}
                    label={'모니터링'}
                    to={`/main/monitor`}
                  />
                  <Menu
                    auth={MENU.REPORT}
                    collapsed={this.collapsed}
                    icon={<PresentionChart />}
                    label={'리포트'}
                    to={`/main/report`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {isAuthorization([
              MENU.TEAM,
              MENU.TEAM_AGENT,
              MENU.AGENT,
              MENU.BOT_MESSAGE,
              MENU.NOTICE,
              MENU.QUICK,
              MENU.BRANCH,
              MENU.SETTING,
            ]) && (
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <ListLabel label="관리" collapsed={this.collapsed} />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.TEAM}
                    collapsed={this.collapsed}
                    icon={<People />}
                    label="팀"
                    to={`/main/team`}
                  />
                  <Menu
                    auth={MENU.TEAM_AGENT}
                    collapsed={this.collapsed}
                    icon={<UserCirlceAdd />}
                    label={'팀원 관리'}
                    to={`/main/member`}
                  />
                  <Menu
                    auth={MENU.AGENT}
                    collapsed={this.collapsed}
                    icon={<User />}
                    label={'상담사'}
                    to={`/main/agent`}
                  />
                  <Menu
                    auth={MENU.BOT_MESSAGE}
                    collapsed={this.collapsed}
                    icon={<ChatBotMessageIcon className={'MenuTitle'} />}
                    label={'챗봇 메시지'}
                    to={`/main/bot/message/template`}
                  />
                  <Menu
                    auth={MENU.NOTICE}
                    collapsed={this.collapsed}
                    icon={<ClipboardText className={'MenuTitle'} />}
                    label={'공지사항'}
                    to={`/main/notice`}
                  />
                  <Menu
                    auth={MENU.QUICK}
                    collapsed={this.collapsed}
                    icon={<Flashy />}
                    label={'빠른답변'}
                    to={`/main/quickmsg`}
                  />
                  <Menu
                    auth={MENU.BRANCH}
                    collapsed={this.collapsed}
                    icon={<ShopTalk className={'MenuTitle'} />}
                    label={'지점공감'}
                    to={`/main/branch-memo`}
                  />
                  <Menu
                    auth={MENU.SETTING}
                    collapsed={this.collapsed}
                    icon={<Setting2 />}
                    label={'설정'}
                    subMenu={true}
                    to={`/main/setting`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {isAuthorization([
              MENU.SCENARIO,
              MENU.OUTBOUND_CALL,
              MENU.OUTBOUND_SETTING,
            ]) && (
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <ListLabel label="음성설정" collapsed={this.collapsed} />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.SCENARIO}
                    collapsed={this.collapsed}
                    icon={<Book1 className={'MenuTitle'} />}
                    label={'시나리오'}
                    to={`/main/scenario`}
                  />
                  <Menu
                    auth={MENU.OUTBOUND_CALL}
                    collapsed={this.collapsed}
                    icon={<Call className={'MenuTitle'} />}
                    label={'아웃바운드 전화번호'}
                    to={`/main/call`}
                  />
                  <Menu
                    auth={MENU.OUTBOUND_SETTING}
                    collapsed={this.collapsed}
                    icon={<CpuSetting className={'MenuTitle'} />}
                    label={'아웃바운드 설정'}
                    to={`/main/ob-setting`}
                  />
                  <Menu
                    auth={MENU.RECORD}
                    collapsed={this.collapsed}
                    icon={<CpuSetting className={'MenuTitle'} />}
                    label={'녹취'}
                    to={`/main/voice-record`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
            {isAuthorization([
              MENU.STANDARD_SCENARIO,
              MENU.STANDARD_CHAT,
              MENU.ANNOTATION,
            ]) && (
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <ListLabel
                    label="챗 로그 자동화"
                    collapsed={this.collapsed}
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <Menu
                    auth={MENU.STANDARD_SCENARIO}
                    collapsed={this.collapsed}
                    icon={<Verify className={'MenuTitle'} />}
                    label={'시나리오 표준셋'}
                    to={`/main/standard-scenario`}
                  />
                  <Menu
                    auth={MENU.STANDARD_CHAT}
                    collapsed={this.collapsed}
                    icon={<Verify className={'MenuTitle'} />}
                    label={'챗봇 표준셋'}
                    to={`/main/standard-chat`}
                  />
                  <Menu
                    auth={MENU.ANNOTATION}
                    collapsed={this.collapsed}
                    icon={<Autobrightness className={'MenuTitle'} />}
                    label={'어노테이션'}
                    to={`/main/annotation`}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.node,
};

export default SideBar;
