import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from '@qbot-chat/qbot-uikit/grid';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import FrameWrap from '../../components/layout/FrameWrap';
import { makeObservable, observable } from 'mobx';
import { DatePicker } from 'antd';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import { Button as Refresh } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import Panel from '../../components/layout/Panel';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import moment from 'moment';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { CHAT_CONTEXT_PATH, PHONE_CONTEXT_PATH } from '../../const/AppConst';
import _ from 'lodash';

const axiosInstance = axios.create({
  baseURL: `${CHAT_CONTEXT_PATH}`,
  withCredentials: true,
});
const { RangePicker } = DatePicker;
const timezone = 'Asia/Seoul';

const store = new DataGridStore({
  fetchUrl: `/record/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`startTime`, Sort.SORT_DIRECTION.DESC),
});

@observer
class VoiceRecordListView extends Component {
  @observable
  keyword = null;

  @observable
  startDate = null;

  @observable
  endDate = null;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      isModal: false,
      isTable: false,
      excelSource: [],
      excelColumn: [
        {
          title: '기준일자',
          dataIndex: 'basDt',
          key: 'basDt',
        },
        {
          title: '티켓ID',
          dataIndex: 'ticketId',
          key: 'ticketId',
        },
        {
          title: '발신번호',
          dataIndex: 'callerNumber',
          key: 'callerNumber',
        },
        {
          title: '070번호',
          dataIndex: 'trunkNumber',
          key: 'trunkNumber',
        },
        {
          title: '통화시작시간',
          dataIndex: 'startTime',
          key: 'startTime',
        },
        {
          title: '통화종료시간',
          dataIndex: 'endTime',
          key: 'endTime',
        },
        {
          title: '콜백여부',
          dataIndex: 'redirectYn',
          key: 'redirectYn',
        },
        {
          title: '콜백전화번호',
          dataIndex: 'redirectNumber',
          key: 'redirectNumber',
        },
      ],
    };
  }

  componentDidMount() {
    store.refresh();
  }

  componentWillUnmount() {
    store.searchCondition.clear();
  }

  activeHandler = (value) => {
    this.keyword = value;
    this.startDate = value;
    this.endDate = value;
    this.search();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      store.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(store.searchCondition);
    store.searchCondition.set('keyword', this.keyword);
    store.searchCondition.set(
      'startDate',
      this.startDate && moment(this.startDate).tz(timezone).format(),
    );
    store.searchCondition.set(
      'endDate',
      this.endDate && moment(this.endDate).tz(timezone).format(),
    );

    if (!_.isEqual(obj, Object.fromEntries(store.searchCondition))) {
      store.refresh();
    } else {
      store.fetch();
    }
  };

  setDateSorting(dates) {
    if (dates[0]) this.startDate = dates[0];
    if (dates[1]) this.endDate = dates[1];
    this.search();
  }

  onDownload(ticketId) {
    if (!ticketId) return;

    confirmDialogStore.open({
      title: '다운로드',
      summary: `${ticketId} 음원을 다운로드 하시겠습니까?`,
      onOk: () => {
        this.downloadWav(ticketId);
      },
    });
  }

  async downloadWav(ticketId) {
    try {
      const response = await fetch(`${PHONE_CONTEXT_PATH}/${ticketId}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to download wav');
      }

      const blob = await response.blob();
      saveAs(blob, `${ticketId}.wav`);
    } catch (e) {
      console.log('downloadWav Error ::', e);
    }
  }

  render() {
    const { excelColumn, excelSource, isTable } = this.state;
    return (
      <FrameWrap className="w-100 overflow-y-auto">
        <TitlePanel>
          <FormGroup>
            <Col className="justify-content-between align-items-center d-flex">
              <TitlePanelLabel label={'녹취 목록'} />
              {/* <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigate(`/main/voice/download`)}
                >
                  다운로드
                </Button>
              </div> */}
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <FormGroup>
              <Col className="d-flex flex-column">
                <div className="d-flex align-items-center gap-3">
                  <Col className="d-flex align-items-center gap-2">
                    <RangePicker
                      placeholder={['Start Date', 'End Date']}
                      size="large"
                      format={'YYYY-MM-DD'}
                      allowEmpty
                      value={[this.startDate, this.endDate]}
                      onChange={(dates) => {
                        this.setDateSorting(dates);
                      }}
                    />
                    <Refresh
                      shape="circle"
                      icon={<ReloadOutlined />}
                      onClick={() => this.activeHandler(null)}
                    />
                  </Col>
                  <Col>
                    <InputGroup>
                      <Form.Control
                        placeholder="내용 입력"
                        value={this.keyword || ''}
                        onChange={(e) => {
                          this.keyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={'outline-secondary'}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className="flex-default">
          <DataGrid
            store={store}
            keyColumn="basDt"
            columns={[
              <Header
                id="basDt"
                key="basDt"
                name="기준일자"
                width={{ min: 80, current: 100, max: 120 }}
              />,
              <Header
                key="ticketId"
                id="ticketId"
                name="티켓 ID"
                width={{ min: 150, current: 250, max: 400 }}
                printFunction={(id) => (
                  <div className="d-flex">
                    <Badge pill bg="secondary" className="IdBadge">
                      {id}
                    </Badge>
                  </div>
                )}
              />,
              <Header
                id="callerNumber"
                key="callerNumber"
                sortable={true}
                name={`발신번호`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id="trunkNumber"
                key="trunkNumber"
                sortable={true}
                name={`070번호`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id="startTime"
                key="startTime"
                sortable={true}
                name={`통화시작시간`}
                width={{ min: 100, current: 220, max: 250 }}
                type="time"
              />,
              <Header
                id="endTime"
                key="endTime"
                sortable={true}
                name={`통화종료시간`}
                width={{ min: 100, current: 220, max: 250 }}
                type="time"
              />,
              <Header
                key="redirectYn"
                id="redirectYn"
                name="콜백 여부"
                width={{ min: 70, current: 100, max: 130 }}
                printFunction={(isRedirect) => (
                  <Badge bg={isRedirect === true ? 'success' : 'danger'}>
                    {isRedirect === true ? '콜백 O' : '콜백 X'}
                  </Badge>
                )}
              />,
              <Header
                key="redirectNumber"
                id="redirectNumber"
                name="콜백 전화번호"
                width={{ min: 100, current: 200, max: 300 }}
                type="phone"
              />,
            ]}
            onRowClick={(rowData) => {
              this.onDownload(rowData.ticketId);
              // this.downloadWav(rowData.ticketId);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export {
  VoiceRecordListView as default,
  store as store,
  axiosInstance as axiosInstance,
};
